<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <el-button type="primary" plain size="mini" v-if="vehicleSearchFlag" @click="vehicleSearch">查询</el-button>
                        <el-button type="primary" plain size="mini" v-if="vehicleAddFlag" @click="vehicleAdd">新增</el-button>
                        <el-button type="primary" plain size="mini" v-if="vehicleModifyFlag" @click="vehicleModify">修改</el-button>
                        <el-button type="primary" plain size="mini" v-if="vehicleDelFlag" @click="vehicleDel">删除</el-button>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-input maxlength="50" placeholder="请输入车牌号" v-model="dataForms.truckNo" size="mini" style="width: 250px"  class="input-with-input">
                            <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
                        </el-input>
                        <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog_seach = true">高级查询</el-button>
                    </div>
                </el-col>
                <el-drawer
                        title="车辆高级查询条件"
                        :before-close="cancelForm"
                        :visible.sync="dialog_seach"
                        direction="rtl"
                        custom-class="demo-drawer"
                        ref="drawer"
                >
                    <div class="demo-drawer__content">
                        <el-row>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-card class="box-card">
                                        <div>
                                            <el-form label-width="140px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                                                <el-form-item label="承运商编码" prop="carriersId">
                                                    <el-input   v-model="dataForms.carriersId"  style="width: 220px"></el-input>
                                                </el-form-item>
                                                <el-form-item label="承运商名称" prop="carriersName">
                                                    <el-input   v-model="dataForms.carriersName" placeholder="支持模糊搜索" style="width: 220px"></el-input>
                                                </el-form-item>
                                                <el-form-item label="车牌号" prop="truckNo">
                                                    <el-input   v-model="dataForms.truckNo" placeholder="支持模糊搜索" style="width: 220px"></el-input>
                                                </el-form-item>
                                                <el-form-item label="司机编码" prop="driverId">
                                                    <el-input   v-model="dataForms.driverId"  style="width: 220px"></el-input>
                                                </el-form-item>
                                                <el-form-item label="司机姓名" prop="driverName">
                                                    <el-input   v-model="dataForms.driverName"  style="width: 220px"></el-input>
                                                </el-form-item>
                                            </el-form>
                                            <div slot="footer" class="dialog-footer" style="text-align: center">
                                                <el-button size="mini" type="primary" @click="handleClose" :loading="loading">{{ loading ? '提交中 ...' : '查 询' }}</el-button>
                                                <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </el-col>

                        </el-row>
                    </div>
                </el-drawer>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @refresh="handleCurrentChange"
                    ref='cesTable'
            >
            </ces-table>
        </div>
        <el-dialog
                :title="dialogtitile"
                :visible.sync="dialog_vehicleData"
                width="75%"
                :modal="true"
                center
                :before-close="handleClose_vehicleData">
            <el-card class="box-card" :body-style="{ padding: '10px' }">
                <div slot="header">
                    <span style="font-size: 14px">车辆信息</span>
                </div>
                <div>
                    <el-form :model="vehicleDataForm"  ref="vehicleDataForm" :rules="vehicleDataFormrules" label-width="80px" class="demo-ruleForm" size="mini" label-position="center">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="承运商" prop="carriersName">
                                    <el-input v-model="vehicleDataForm.carriersName" @focus="vehicleData_onInputFocus"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="司机" prop="driverId">
                                  <el-input v-model="vehicleDataForm.driverName" @focus="oninput_driver"></el-input>
<!--                                    <el-select v-model="vehicleDataForm.driverName"-->
<!--                                               filterable placeholder="请选择司机账号"-->
<!--                                               @change="selectVeData"-->
<!--                                    >-->
<!--                                        <el-option-->
<!--                                                v-for="item in options"-->
<!--                                                :key="item.driverId"-->
<!--                                                :label="item.driverName"-->
<!--                                                :value="{ value: item.driverId, label: item.driverName }"-->
<!--                                        >-->
<!--                                        </el-option>-->
<!--                                    </el-select>-->
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="车牌号"  v-model="showClass" v-if="showClass==true" prop="truckNo">
                                  <el-select v-model="vehicleDataForm.truckNo"
                                             placeholder="请选择车牌号"
                                             @change="selectVeDataDriver"
                                  >
                                    <el-input v-model="vehicleDataForm.truckNo" placeholder="请选择车牌或者手工输入"></el-input>
                                    <el-option
                                        v-for="item in optionsl"
                                        :key="item"
                                        :label="item"
                                        :value="{ value: item, label: item }"
                                    >
                                    </el-option>
                                    <el-option>
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              <el-form-item label="车牌号" v-model="showClass1" v-if="showClass1==true" prop="truckNo">
                                <el-input v-model="vehicleDataForm.truckNo" placeholder="请输入车牌号"></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="车载重" prop="truckWeight">
                                    <el-input v-model="vehicleDataForm.truckWeight"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="save_vehicleData">提 交</el-button>
                <el-button @click="handleClose_vehicleData">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog title="承运商查询" :visible.sync="dialog_comm_carriers">
            <el-row>
                <el-form :inline="true"  v-model="carriers_form" size="mini" label-position="center">
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="编码" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="carriers_form.carriersId" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="名称" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="carriers_form.carriersName" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content">
                            <el-button type="primary" plain size="mini" @click="companySearch">查询</el-button>
<!--                            <el-button type="primary" plain size="mini" @click="companyOkay">确定</el-button>-->
                        </div>
                    </el-col>
                </el-form>
            </el-row>
            <el-row class="waybill_add">
                <flight-table
                        :tableData="tableDatadialog"
                        :columns="columns"
                        :pagesizes="pagesizes"
                        :isPagination='true'
                        @handleSelectionChange="handleSelectionChange"
                        @clickTable="clickTable"
                        @handleCurrentChange="handleCurrentChange_carriers"
                        @handleSizeChange = 'handleSizeChange'
                        :total="tableDatadialog_pagination.total"
                        :pageSize="tableDatadialog_pagination.pageSize"
                        :current-page="tableDatadialog_pagination.pageNum"
                ></flight-table>
            </el-row>
        </el-dialog>
      <el-dialog title="司机查询" :visible.sync="dialogTableVisible_driver">
        <el-row>
          <el-form :inline="true"  v-model="formDriver" size="mini" label-position="center">
            <el-col :span="9">
              <div class="grid-content">
                <el-form-item label="司机编码" label-width="100px" style="width: 220px;">
                  <el-input size="mini" v-model="formDriver.driverId" style="width: 120px;"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="grid-content">
                <el-form-item label="名称" label-width="60px" style="width: 220px;">
                  <el-input size="mini" v-model="formDriver.driverName" style="width: 150px;"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <el-button type="primary" plain size="mini" @click="driverSearch">查询</el-button>
                <!--<el-button type="primary" plain size="mini" @click="driverOkay">确定</el-button>-->
              </div>
            </el-col>
          </el-form>
        </el-row>
        <el-row class="waybill_add">
          <flight-table
              :tableData="tableDatadialog_driver"
              :columns="columns_driver"
              :pagesizes="pagesizes_driver"
              :isPagination='true'
              @handleSelectionChange="handleSelectionChange_driver"
              @handleCurrentChange="handleSelectionChange_driver_a"
              @clickTable="clickTable_driver"
              :total="tableDatadialog_pagination_driver.total"
              :pageSize="tableDatadialog_pagination_driver.pageSize"
              :current-page="tableDatadialog_pagination_driver.pageNum"
          ></flight-table>
        </el-row>
      </el-dialog>
    </div>
</template>

<script>
    //引入组件
    import cesTable from '../../components/common/table.vue'
    import axios from 'axios'
    import qs from 'qs'
    import arrayQuery from '../../components/arrayQuery.js'
    import 'element-ui/lib/theme-chalk/index.css'
    import flightTable from "../../components/common/flightTable.vue"
    export default {
        data(){
            return {
                table: false,
                dialog: false,
                loading: false,
                dialog_seach:false,
                dialog_vehicleData:false,
                dialog_comm_carriers:false,
              dialogTableVisible_driver:false,
                showClass: true,
                showClass1: true,
                dialogtitile:'',
                options:[],
              optionsl:[],
                carriers_form:{
                    carriersId:'',
                    carriersName:'',
                    companyType:1
                },

                tableDatadialog: [],
                columns: [
                    {
                        id: "selection",
                        type: "selection",
                        label: "",
                        width: "55",
                        prop: "",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "carriersId",
                        type: "",
                        label: "编码",
                        fixed: false,
                        width: "280",
                        prop: "carriersId",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "carriersName",
                        type: "",
                        label: "名称",
                        fixed: false,
                        width: "280",
                        prop: "carriersName",
                        isShow: true,
                        align: "center"
                    }
                ],
              formDriver:{
                driverId:'',
                driverName:''
              },
              query_default_license_plate:{
                carriersId:'',
                driverId:'',
              },
                tableDatadialog_pagination:{
                    pageSize:5,
                    pageNum:1,
                    total:0
                },
              tableDatadialog_pagination_driver:{
                pageSize:5,
                pageNum:1,
                total:0
              },
              tableDatadialog_driver:[],
              columns_driver:[
                {
                  id: "driverId",
                  type: "",
                  label: "用户id",
                  fixed: false,
                  width: "319",
                  prop: "id",
                  isShow: true,
                  align: "center"
                },
                {
                  id: "driverName",
                  type: "",
                  label: "用户名称",
                  fixed: false,
                  width: "319",
                  prop: "username",
                  isShow: true,
                  align: "center"
                }
              ],
                pagesizes: [5, 10, 15, 20],
              pagesizes_driver:[5, 10, 15, 20],
                dataForms:{
                    truckNo:'',
                    carriersId:'',
                    carriersName:'',
                    driverId:'',
                    driverName:'',
                },
                vehicleDataForm:{

                    truckNo: '',
                    truckWeight:'0',
                    carriersId:'',
                    carriersName:'',
                    driverId:'',
                    driverName:''
                },
                vehicleDataFormrules: {
                    truckNo: [
                        { required: true, message: '请输入车牌', trigger:['blur', 'change']},
                        {
                            pattern: "^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$",
                            message: "请输入正确的车牌号"
                        }
                    ],
                    carriersName: [
                        { required: true,message: '请输入承运商名称', trigger: ['blur', 'change'] }
                    ],
                    driverId: [
                        { required: true,message: '请选择司机账号', trigger: ['blur', 'change'] }
                    ],
                    phone: [
                        { required: true, message: "请输入手机号码", trigger: "blur" },
                        { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
                        {
                            pattern: '^1[345789]\\d{9}$',
                            message: "请输入正确的手机号码",
                            trigger: "blur"
                        }
                    ]
                },
                formLabelWidth: '80px',
                timer: null,
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableCols:[ //表格列数据
                    {label:'车牌号',prop:'truckNo',width:'120',align:'center'},
                    {label:'承运商编码',prop:'carriersId',width:'120',align:'center'},
                    {label:'承运商名称',prop:'carriersName',width:'160',align:'center'},
                    {label:'司机编码',prop:'driverId',align:'center'},
                    {label:'司机姓名',prop:'driverName',align:'center'},
                    {label:'车载重',prop:'truckWeight',align:'center',width:'120'},
                    {label:'创建人员',prop:'creator',align:'center',width:'120'},
                    {label:'创建时间',prop:'createTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.createTime == null){
                                return ''
                            }else {
                                return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                            }

                        }
                    },
                  {label:'修改时间',prop:'modifyTime',align:'center',width:'160',
                    formatter: (row, column) => {
                      if(row.modifyTime == null){
                        return ''
                      }else {
                        return this.$moment(row.modifyTime).format("YYYY-MM-DD HH:mm:ss")
                      }

                    }
                  },
                  {label:'修改人',prop:'modifier',align:'center'},
                ],
                pagination:{ //分页数据
                    pageSize:50,
                    pageNum:1,
                    total:0
                },
                merge:[],
                merge_carrier:[],
                vehicleSearchFlag:true,
                vehicleAddFlag:true,
                vehicleModifyFlag:true,
                vehicleDelFlag:true,
                truckNoFlag:false
            }
        },

        components:{ //引入组件后注册组件
            cesTable,
            flightTable
        },
        mounted(){
            this.getTableAndForm();
            let parentId = '2_3_5';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;
            //查询按钮权限
            let vehicleSearch = currentPageButtons.find(item => {
                return item.menuId == '2_3_5_1';
            });
            if(vehicleSearch == undefined){
                _this.vehicleSearchFlag = false;
            }else {
                _this.vehicleSearchFlag = true;
            }
            //新增按钮权限
            let vehicleAdd = currentPageButtons.find(item => {
                return item.menuId == '2_3_5_2';
            });
            if(vehicleAdd == undefined){
                _this.vehicleAddFlag = false;
            }else {
                _this.vehicleAddFlag = true;
            }
            //修改按钮权限
            let vehicleModify = currentPageButtons.find(item => {
                return item.menuId == '2_3_5_3';
            });
            if(vehicleModify == undefined){
                _this.vehicleModifyFlag = false;
            }else {
                _this.vehicleModifyFlag = true;
            }
            //删除按钮权限
            let vehicleDel = currentPageButtons.find(item => {
                return item.menuId == '2_3_5_4';
            });
            if(vehicleDel == undefined){
                _this.vehicleDelFlag = false;
            }else {
                _this.vehicleDelFlag = true;
            }
        },
        methods:{

            handleSizeChange(val){
                this.tableDatadialog_pagination.pageSize = val;
                this.companySearch();
            },
            // selectVeData(data){
            //     const { value, label } = data;
            //     this.vehicleDataForm.driverId = value;
            //     this.vehicleDataForm.driverName = label;
            //     this.queryDriverTruckNos();
            //    this.vehicleDataForm.truckNo = "";
            // },
          selectVeDataDriver(data){
            const { value, label } = data;
            this.vehicleDataForm.truckNo = value;
            this.vehicleDataForm.truckNo = label;
          },
            vehicleSearch_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.vehicleSearch();
            },
            handleClose() {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.timer = setTimeout(() => {
                    // 动画关闭需要一定的时间
                    setTimeout(() => {
                        this.loading = false;
                    }, 400);
                    this.vehicleSearch_Multiple();
                    // 动画关闭需要一定的时间
                    this.dialog_seach = false;
                }, 2000);
            },
            cancelForm() {
                this.loading = false;
                this.dialog_seach = false;
                clearTimeout(this.timer);
            },
            handleCurrentChange_carriers(val){
                this.tableDatadialog_pagination.pageNum = val;
                this.companySearch();
            },
            save_vehicleData(){
                this.$refs["vehicleDataForm"].validate(valid => {
                    if (valid) {
                        if( this.dialogtitile == '车辆新增'){
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/urban/vehicle/saveVehicle`,
                                data:this.vehicleDataForm
                            }).then(res=> {

                                if(res.data.success == true){
                                    this.$message({
                                        message: '新增成功',
                                        type: 'success'
                                    });
                                    this.handleClose_vehicleData();
                                    this.vehicleSearch();
                                  console.log( this.vehicleDataForm);
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',

                                    });

                                }
                            });
                        }else if(this.dialogtitile == '车辆修改'){
                            this.truckNoFlag = true;
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/urban/vehicle/updateVehicle`,
                                data:this.vehicleDataForm
                            }).then(res=> {
                              console.log( this.vehicleDataForm);
                                if(res.data.success == true){
                                    this.$message({
                                        message: '修改成功',
                                        type: 'success'
                                    });
                                    this.handleClose_vehicleData();
                                    this.vehicleSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                }
                            });
                        }
                    } else {
                        this.$message.error("请检查您输入的数据,请核对后重试!");
                    }
                });
            },


            handleClose_vehicleData(){
                this.dialog_vehicleData = false;
                this.truckNoFlag = false;
                this.$refs.vehicleDataForm.resetFields();
            },
            //打开查询承运商
            vehicleData_onInputFocus(){
                this.dialog_comm_carriers = true;
              this.carriers_form.carriersId = "";
              this.carriers_form.carriersName = "";
                this.companySearch();
            },
            // checkbox的触发接收
            handleSelectionChange(val) {
                this.merge_carrier = val.val;
            },
            //  承运商双击事件的行触发
            clickTable(val) {
                this.vehicleDataForm.carriersId = val.row.carriersId;
                this.vehicleDataForm.carriersName = val.row.carriersName;
                this.query_default_license_plate.driverId = val.row.driverId;
                this.query_default_license_plate.carriersId = val.row.carriersId;
                this.dialog_comm_carriers = false;
                // this.companySearchVieData();
                 this.vehicleDataForm.driverName = "";
                 this.vehicleDataForm.truckNo = "";
            },
          //选择司机
          oninput_driver(){
            //    selectVeData(data){
            //      const { value, label } = data;
            //      this.deliveryForm.driverId = value;
            //      this.deliveryForm.driverName = label;
            if(this.vehicleDataForm.carriersId == ''){
              this.$notify({
                title: '警告',
                message: '查询司机前应先查询承运商',
                type: 'warning'
              });
            }else {
              this.dialogTableVisible_driver = true;
              this.formDriver.driverId = "";
              this.formDriver.driverName = "";
              this.driverSearch();
              this.queryDriverTruckNos();
              this.vehicleDataForm.truckNo = "";
            }
          },
          handleSelectionChange_driver(val) {
            this.merge_driver = val.val;
          },
          handleSelectionChange_driver_a(val){
            this.tableDatadialog_pagination_driver.pageNum = val;
            this.driverSearch();
          },
          clickTable_driver(val){
            this.vehicleDataForm.driverId = val.row.driverId;
            this.query_default_license_plate.driverId = val.row.driverId;
            this.vehicleDataForm.driverName = val.row.driverName;
            this.dialogTableVisible_driver = false;
            this.queryDriverTruckNos();
          },
          // 承运商所属司机
          driverSearch(){
            const data = new URLSearchParams();
            data.append('driverId', this.formDriver.driverId);
            data.append('driverName', this.formDriver.driverName);
            data.append('carriersId', this.vehicleDataForm.carriersId);
            data.append('pageSize', this.tableDatadialog_pagination_driver.pageSize);
            data.append('pageNo', this.tableDatadialog_pagination_driver.pageNum);
            axios({
              method: 'post',
              // url: `${this.$apiPath}/api/urban/truck/queryDrivers`,
              url: `${this.$apiPath}/api/urban/vehicle/queryVehicleDriversPage`,
              data:data
            }).then(res=> {
              this.tableDatadialog_driver = res.data.data.items;
              this.tableDatadialog_pagination_driver.total = res.data.data.total;
              // this.options = res.data.data.items;
              // console.log(this.tableDatadialog_driver);
            });
          },
            companySearchVieData(){
                const data = new URLSearchParams();
                data.append('carriersId', this.vehicleDataForm.carriersId);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/vehicle/queryVehicleDriversPage`,
                    data:data
                }).then(res=> {
                    this.options = res.data.data.items;
                  console.log(this.options);
                });
            },
          // 承运商所属司机车牌下拉列表
          queryDriverTruckNos(){
            const data = new URLSearchParams();
            data.append('driverId', this.vehicleDataForm.driverId);
            console.log( this.vehicleDataForm.driverId);
            axios.get(`${this.$apiPath}/api/urban/vehicle/queryDriverTruckNos/${this.vehicleDataForm.driverId}` )
                .then(res=> {
              this.optionsl = res.data.data;
                  console.log( this.optionsl);
            });
          },
          // 承运商
            companySearch(){
                const data = new URLSearchParams();
                data.append('carriersId', this.carriers_form.carriersId);
                data.append('carriersName', this.carriers_form.carriersName);
                data.append('companyType', this.carriers_form.companyType);
                data.append('pageSize', this.tableDatadialog_pagination.pageSize);
                data.append('pageNo', this.tableDatadialog_pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/vehicle/queryVehicleCarriersPage`,
                    data:data
                }).then(res=> {
                    this.tableDatadialog = res.data.data.items;
                    this.tableDatadialog_pagination.total = res.data.data.total;
                });
            },
            companyOkay(){
                if(this.merge_carrier.length == 0){
                    this.$message({
                        message: '警告，请进行查询并选择一条数据',
                        type: 'warning'
                    });
                }else if(this.merge_carrier.length > 1){
                    this.$message({
                        message: '警告，承运商只允许选择一条数据',
                        type: 'warning'
                    });
                }else {
                    this.vehicleDataForm.carriersId = this.merge_carrier[0].carriersId;
                    this.vehicleDataForm.carriersName = this.merge_carrier[0].carriersName;
                    this.dialog_comm_carriers = false;
                }
            },
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.vehicleSearch()
            },
            //获取表格和表单数据
            async getTableAndForm(){
                this.vehicleSearch()
            },
            handleQuery(){ //查询
                //快捷查询
                this.vehicleSearch_Multiple()
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            //订单查询
            vehicleSearch(){
                this.loading = true;
                const data = new URLSearchParams();
                data.append('carriersId', this.dataForms.carriersId);
                data.append('carriersName', this.dataForms.carriersName);
                data.append('truckNo', this.dataForms.truckNo);
                data.append('driverId', this.dataForms.driverId);
                data.append('driverName', this.dataForms.driverName);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/vehicle/queryVehiclesPage`,
                    data:data
                }).then(res=> {
                  console.log(res.data);
                    this.tableData = res.data.data.items;
                    this.pagination.total = res.data.data.total;
                    this.loading = false;
                });
            },
            vehicleSearch_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.vehicleSearch();
            },
            //新增
            vehicleAdd(){
                this.dialogtitile = '车辆新增';
                this.truckNoFlag = false;
                this.dialog_vehicleData = true;
                this.showClass = false;
              this.showClass1 = true;
                //this.$router.replace({ path: "/vehicleAdd" });
            },
            //修改
            vehicleModify(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '修改操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '修改操作只能选择单条数据进行操作'
                    });
                }else{
                    this.dialogtitile = '车辆修改';
                    this.truckNoFlag = true;
                    this.dialog_vehicleData = true;
                    this.showClass = true;
                    this.showClass1 = false;
                    const truckNo = new URLSearchParams();
                    truckNo.append('id', this.merge[0].id);
                    axios({
                        method: 'get',
                        url: `${this.$apiPath}/api/urban/vehicle/queryVehicleById/${this.merge[0].id}`,
                      // url: '/api/urban/vehicle/queryVehicleById/' + '?id=' + id
                      // data:truckNo
                    }).then(res=> {
                      console.log(res.data);
                        this.vehicleDataForm = res.data.data;
                    });
                }
            },
            //删除
            vehicleDel(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '删除操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '删除操作只能选择单条数据进行操作'
                    });
                }else{
                    this.$confirm('此操作将对车辆信息进行删除, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        const waybilldata = new URLSearchParams();
                        waybilldata.append('id', this.merge[0].id);
                        axios({
                            method: 'get',
                            url: `${this.$apiPath}/api/urban/vehicle/deleteVehicle/${this.merge[0].id}`,
                          // url: `${this.$apiPath}/api/urban/vehicle/deleteVehicle/{id}`,
                          //   data:waybilldata
                        }).then(res=> {
                          // console.log(res.data);
                            if(res.data.success == true){
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.vehicleSearch();
                            }else {
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });
                            }
                        });
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                    });
                }
            }
        }
    }
</script>
<style>

    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
    .waybill_add .ces-table{
        height: 200px;
    }
    .waybill_add .el-table th{
        padding: 0;
        height: 30px;
    }
    .waybill_add .el-table td{
        padding: 0;
        height: 30px;
    }
</style>
<style scoped>
    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
</style>
